export const fr_analytics = {
  analytics: 'Analytique',
  selectAll: 'Tout sélectionner',
  chats: 'Chats',
  visits: 'Visites',
  close: 'Fermer',
  print: 'Impression',
  applyToAll: "S'applique à tous",
  nonFilteredGraphs: 'Graphiques non filtrés',
  noUsersAvailable: 'Aucun utilisateur n`Fest disponible',
  noData_Available: 'Pas de données disponibles',
  dataBeingFetched:
    'Veuillez patienter, les données sont en cours de récupération...',
  lastHours: 'Dernières 24 heures',
  lastDays: 'Les 30 derniers jours',
  lastMonths: '6 derniers mois',
  pleaseWait:
    'Veuillez patienter, les données sont en cours de récupération...',
  addMore: 'Ajouter plus',
  customize: 'Personnaliser',
  forThePastYear: 'Pour la dernière année',
  quickStatsLegendTextFor6Months:
    'La comparaison des données de 6 mois avec les 6 mois précédents n`est actuellement pas disponible.',
  chatsLeads: 'Chat contre plomb',
  featuresUsage: 'Utilisation des fonctionnalités',
  chatReferrals: 'Parrainages de chat',
  chatStatsBy: 'Statistiques de chat par',
  accountsActivation: 'Activation des comptes',
  topBottomLeadAccounts: 'Comptes principaux',
  topInactiveBillers: 'Principaux facturiers inactifs',
  budgetPacing: 'Budget/rythme',
  agentImages: "CTR des images de l'agent",
  agentCTR: 'Agent CTR',
  accounts: 'Comptes',
  filters: 'Filtres',
  clearAll: 'Tout effacer',
  clear: 'Dégager',
  features: 'Caractéristiques',
  devices: 'Dispositifs',
  user: 'Utilisateur',
  companyKey: 'Clé de l`entreprise',
  typeOfBusiness: 'Types d`entreprises',
  ChartsShowHide: 'Afficher/Masquer les graphiques',
  applyFilters: 'Appliquer des filtres',
  apply: 'Appliquer',
  totalVisits: 'Nombre total de visites',
  totalChats: 'Total des Causeries',
  totalLeads: 'Leads au total',
  totalBillableLeads: 'Nombre total de prospects facturables',
  clickThroughRate: 'Taux de clics',
  leadConversionRate: 'Lead taux de conversion',
  blankChats: 'Discussions vierges',
  activeCallConnect: " Connexion d'appel active",
  callConnectAttempts: "Tentatives de connexion d'appel",
  callConnectDuration: "Durée de connexion d'appel (minutes)",
  activeChatsForHosts: 'Chats actifs pour les hôtes',
  activeChats: 'Discussions actives',
  ctr: 'CTR',
  lcr: 'LCR',
  signedRetainers: 'Retenues roussies',
  totalDocumentSignedLeads: 'Retenues roussies',
  topLeadGenerators: 'Principaux générateurs de plomb',
  filterApplied: 'Filters applied',
  quickStats: 'Statistiques rapides',
  charts: 'Graphiques',
  label1: 'Les 30 derniers jours',
  label2: 'Augmentation par rapport aux 30 jours précédents',
  label3: 'Diminution par rapport aux 30 jours précédents',
  label4: 'Dans le',
  label5: 'Pourcentage',
  label6: 'Des augmentations de la',
  label7: 'Diminue de la',
  startTour: {
    label: 'Commencer tour'
  },
  tourIntro1:
    "Ceci est la page de démarrage d'analyse et de ses widgets de Quick",
  tourIntro2: 'Graphiques ..',
  tourIntro3: 'Total visites ..',
  tourIntro4: 'Total des Causeries ..',
  tourIntro5: 'Total des Leads ..',
  tourIntro6: 'Facturables Leads ..',
  tourIntro7: 'Taux de clics..',
  tourIntro8: 'Lead taux de conversion ..',
  tooltip1:
    'Les tableaux/graphiques ci-dessous ne sont filtrés par aucun filtre disponible comme fonctionnalité, utilisateur, appareils, etc.',
  filtersTooltip1:
    ' Les modifications apportées à cette section s`appliquent à:',
  homeBannerHeading:
    'Boostez la génération de leads avec notre fonctionnalité Call Center',
  homeBannerTextDirect:
    "Obtenez de précieux contacts avec les profils d'entreprise Google grâce à nos agents de chat en direct chez Blazeo (anciennement ApexChat).",
  homeBannerText:
    "Notre centre d'appels stimule la génération de leads en gérant et en suivant efficacement les clients potentiels, garantissant ainsi des taux de conversion plus élevés.",
  learnMore: 'Apprendre encore plus'
};

export default fr_analytics;
